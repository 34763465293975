import React from "react";

export const Shipbob = () => {
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85929 11.4705V16.8689L10 21.1877L17.1482 16.8689V9.77381L11.0534 13.4448C10.3837 13.8457 9.6162 13.8457 8.94652 13.4448L0.654608 8.43969C-0.218203 7.90755 -0.218203 6.57329 0.654608 6.04896L8.94652 1.03615C9.6162 0.635087 10.3837 0.635087 11.0534 1.03615L17.1482 4.72251L14.3416 6.41139L10 3.79698L7.9684 5.03097L4.29647 7.25966L6.23026 8.42421L10 10.7225L17.705 6.07978C18.1414 5.82528 18.7359 5.8561 19.1722 6.08744C19.6462 6.34194 20 6.84326 20 7.46034V17.3394C20 18.1106 19.6237 18.7814 18.9766 19.1748L11.0534 23.964C10.3913 24.365 9.6162 24.365 8.94652 23.964L1.02333 19.1748C0.376235 18.7814 0.007517 18.1182 0.007517 17.3394V15.7738V9.75847L2.85929 11.4705Z"
        fill="#175CFF"
      />
    </svg>
  );
};
