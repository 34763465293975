import React from "react";
export const Cogsy = () => {
  return (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5 22.3228L22.946 17.9002L7.04867 20.4433L21.6111 15.5888L19.8588 12.5528L6.09249 19.0618L18.53 10.2527L17.2022 7.95379L5.47984 17.7507L15.8721 5.65005L14.8859 3.94273L5.04177 16.3805L13.5545 1.63732L12.9998 0.67688L0.5 22.3232L25.5 22.3228Z"
        fill="#7A4DFF"
      />
    </svg>
  );
};
