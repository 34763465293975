import React from "react";

import { Arpu } from "./logos/Arpu";
import { Attentive } from "./logos/Attentive";
import { Bub } from "./logos/Bub";
import { Churnbuster } from "./logos/Churnbuster";
import { Cogsy } from "./logos/Cogsy";
import { Gorgias } from "./logos/Gorgias";
import { Klaviyo } from "./logos/Klaviyo";
import { Littledata } from "./logos/Littledata";
import { Peel } from "./logos/Peel";
import { Rebuy } from "./logos/Rebuy";
import { Recharge } from "./logos/Recharge";
import { Shipbob } from "./logos/Shipbob";
import { ShopifyFlow } from "./logos/ShopifyFlow";

export enum IntegrationBrands {
  Alloy, // removed
  Gorgias,
  Klaviyo,
  Postscript,
  Recharge,
  Attentive,
  Churnbuster,
  Cogsy,
  Littledata,
  Peel,
  Shipbob,
  Rebuy,
  ShopifyFlow,
  Arpu,
}

interface Props {
  brand: IntegrationBrands;
  /**
   * If available, displays an alternative version of brand's logo
   */
  alt?: boolean;
}

export const IntegrationLogo = ({ brand, alt = false }: Props): JSX.Element => {
  switch (brand) {
    case IntegrationBrands.Gorgias: {
      return <Gorgias />;
    }
    case IntegrationBrands.Klaviyo: {
      return <Klaviyo />;
    }
    case IntegrationBrands.Postscript: {
      return <Bub />;
    }
    case IntegrationBrands.Recharge: {
      return <Recharge />;
    }
    case IntegrationBrands.Attentive: {
      return <Attentive />;
    }
    case IntegrationBrands.Churnbuster: {
      return <Churnbuster />;
    }
    case IntegrationBrands.Cogsy: {
      return <Cogsy />;
    }
    case IntegrationBrands.Littledata: {
      return <Littledata />;
    }
    case IntegrationBrands.Peel: {
      return <Peel />;
    }
    case IntegrationBrands.Shipbob: {
      return <Shipbob />;
    }
    case IntegrationBrands.Rebuy: {
      return <Rebuy />;
    }
    case IntegrationBrands.ShopifyFlow: {
      return <ShopifyFlow />;
    }
    case IntegrationBrands.Arpu: {
      return <Arpu alt={alt} />;
    }
    default: {
      // Shouldn't hit here
      return <React.Fragment />;
    }
  }
};
