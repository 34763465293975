import React from "react";

export const Littledata = () => {
  return (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.15 14.9749C16.05 12.7249 15.05 8.8249 12.65 6.0749C10.8 3.9249 7.1 4.6249 6.6 6.4749C6.6 6.4749 4.7 5.9749 3.1 6.0249C1.5 6.0749 0.649994 6.2749 0.649994 6.2749L0.5 6.6249C0.5 6.6249 2.69999 6.1249 4.64999 6.5749C6.24999 6.9249 7.09999 7.5749 7.64999 8.7749C8.19999 9.9749 8.8 14.0249 11.5 16.2249C14.15 18.4249 19.3 18.5249 19.3 18.5249C19.3 18.5249 18 16.7749 17.15 14.9749ZM9.7 7.4249C9.45 7.4749 9.15 7.3249 9.1 7.0749C9.05 6.8249 9.2 6.5249 9.45 6.4749C9.7 6.4249 10 6.5749 10.05 6.8249C10.15 7.0749 10 7.3249 9.7 7.4249Z"
        fill="#0FA69D"
      />
      <g opacity="0.6">
        <path
          d="M19.7 0.474976L19.2 1.37498C22.1 4.87498 23.9 9.02498 24.5 13.325L25.5 13.525C24.9 8.77498 22.95 4.22498 19.7 0.474976Z"
          fill="#0FA69D"
        />
        <path
          d="M18.1001 3.32495L17.55 4.27495C19.45 6.82495 20.7 9.72495 21.3 12.775L22.3501 12.975C21.7501 9.47495 20.3001 6.17495 18.1001 3.32495Z"
          fill="#0FA69D"
        />
        <path
          d="M16.55 6.0249L16 7.0249C17.05 8.6249 17.8 10.3749 18.25 12.1749L19.35 12.3749C18.8 10.1249 17.85 7.9749 16.55 6.0249Z"
          fill="#0FA69D"
        />
      </g>
    </svg>
  );
};
