import React from "react";

export const Rebuy = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 12.5C25.5 19.2916 19.9035 24.7974 13 24.7974C6.09644 24.7974 0.5 19.2916 0.5 12.5C0.5 5.70835 6.09644 0.202637 13 0.202637C19.9035 0.202637 25.5 5.70835 25.5 12.5ZM10.1867 10.9607L10.0353 10.8007C9.6034 10.3878 9.04015 10.1627 8.44504 10.1627C7.18101 10.1627 6.13801 11.1944 6.13801 12.4788C6.13801 13.7632 7.18101 14.795 8.44504 14.795C9.04267 14.795 9.60807 14.5679 10.0407 14.1515L14.8412 9.37399L14.846 9.36917L14.851 9.3645C15.6521 8.61089 16.7018 8.19399 17.8051 8.19399C20.1885 8.19399 22.1128 10.1308 22.1128 12.4955C22.1128 14.8603 20.1885 16.7971 17.8051 16.7971C16.7018 16.7971 15.6521 16.3803 14.851 15.6266L14.8474 15.6233L14.8438 15.6198L14.8322 15.6084L14.8281 15.6043L14.824 15.6002L14.5632 15.3318L14.2364 14.9955L14.5678 14.6637L15.2927 13.9382L15.6457 13.5852L15.9933 13.9432L16.2405 14.1976C16.6687 14.5946 17.2228 14.8116 17.8051 14.8116C19.0691 14.8116 20.112 13.7798 20.112 12.4953C20.112 11.211 19.0691 10.1792 17.8051 10.1792C17.2214 10.1792 16.6659 10.3972 16.2372 10.7963L11.4471 15.5639L11.4447 15.5663L11.4422 15.5687C10.6357 16.3487 9.57028 16.7805 8.44504 16.7805C6.06149 16.7805 4.13719 14.8436 4.13719 12.4788C4.13719 10.114 6.06149 8.17717 8.44504 8.17717C9.57028 8.17717 10.6357 8.60881 11.4422 9.38888L11.4424 9.38903L11.4489 9.39533L11.4565 9.40274L11.4638 9.41045L11.6257 9.58155L11.9437 9.9176L11.6164 10.245L10.8916 10.9701L10.5341 11.3277L10.1867 10.9607Z"
        fill="#1B2140"
      />
    </svg>
  );
};
