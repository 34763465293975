import React from "react";
export const Churnbuster = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6745_83475)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.00008 0.499956C2.60235 0.485987 2.206 0.554031 1.83571 0.699852C1.46541 0.845673 1.12909 1.06615 0.847681 1.34756C0.566272 1.62897 0.345795 1.96529 0.199974 2.33558C0.0541533 2.70588 -0.0138905 3.10223 7.79831e-05 3.49996V19.5C-0.0138905 19.8977 0.0541533 20.294 0.199974 20.6643C0.345795 21.0346 0.566272 21.3709 0.847681 21.6524C1.12909 21.9338 1.46541 22.1542 1.83571 22.3001C2.206 22.4459 2.60235 22.5139 3.00008 22.5H19.0001C19.3978 22.5139 19.7942 22.4459 20.1644 22.3001C20.5347 22.1542 20.8711 21.9338 21.1525 21.6524C21.4339 21.3709 21.6544 21.0346 21.8002 20.6643C21.946 20.294 22.014 19.8977 22.0001 19.5V3.49996C22.014 3.10223 21.946 2.70588 21.8002 2.33558C21.6544 1.96529 21.4339 1.62897 21.1525 1.34756C20.8711 1.06615 20.5347 0.845673 20.1644 0.699852C19.7942 0.554031 19.3978 0.485987 19.0001 0.499956H3.00008ZM13.9001 10.7C14.6001 10.7 14.9001 11.2 14.5001 11.8L10.5001 17.8C10.1001 18.4 9.90008 18.3 10.1001 17.6L11.1001 13.6C11.1486 13.4464 11.1592 13.2834 11.1312 13.1248C11.1032 12.9663 11.0373 12.8168 10.9391 12.6892C10.8409 12.5615 10.7134 12.4595 10.5673 12.3917C10.4212 12.324 10.2609 12.2925 10.1001 12.3H9.30008C8.60008 12.3 8.30008 11.8 8.70008 11.2L12.7001 5.19996C13.1001 4.59996 13.3001 4.69996 13.1001 5.39996L12.1001 9.39996C12.0516 9.55351 12.0409 9.71651 12.0689 9.87508C12.097 10.0337 12.1629 10.1831 12.2611 10.3107C12.3592 10.4384 12.4868 10.5404 12.6329 10.6082C12.7789 10.6759 12.9392 10.7074 13.1001 10.7H13.9001Z"
          fill="#BD325F"
        />
      </g>
      <defs>
        <clipPath id="clip0_6745_83475">
          <rect width="22" height="22" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
