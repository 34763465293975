import React from "react";

export const Klaviyo = () => {
  return (
    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5704_69149)">
        <path d="M23.5 16.2006H0.5V0.799438H23.5L18.6829 8.50003L23.5 16.2006Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5704_69149">
          <rect width="23" height="15.4012" fill="white" transform="translate(0.5 0.799438)" />
        </clipPath>
      </defs>
    </svg>
  );
};
