import React from "react";

export const Recharge = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9735 15.5C29.9735 23.7696 23.2697 30.4733 15.0002 30.4733C6.73063 30.4733 0.0268555 23.7696 0.0268555 15.5C0.0268555 7.23048 6.73063 0.526703 15.0002 0.526703C23.2697 0.526703 29.9735 7.23048 29.9735 15.5ZM9.00813 21.424H16.4293C16.5432 21.424 16.6002 21.2819 16.5432 21.1825L10.5465 13.7533C10.461 13.6539 10.3186 13.6965 10.2901 13.8101L8.85143 21.2393C8.83718 21.3388 8.90841 21.424 9.00813 21.424ZM8.86863 10.2098H16.2898C16.3895 10.2098 16.4607 10.295 16.4465 10.3945L15.0078 17.8237C14.9793 17.9374 14.8369 17.98 14.7514 17.8806L8.75467 10.4371C8.68344 10.3519 8.74042 10.2098 8.86863 10.2098ZM17.0443 10.3234C17.0585 10.2666 17.1155 10.2098 17.1867 10.2098H22.5282C22.6137 10.2098 22.6992 10.295 22.6849 10.3803L21.6878 15.21C21.6736 15.281 21.6166 15.3236 21.5454 15.3236H21.3888C18.7536 15.3236 16.6597 12.8945 17.0443 10.3234Z"
        fill="#3901F1"
      />
    </svg>
  );
};
