import React from "react";

export const Gorgias = () => {
  return (
    <svg width="40" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9217 20.5H17.7264C17.6243 20.5 17.5223 20.4706 17.4348 20.4119L14.6361 18.4295C14.5486 18.3708 14.4466 18.3414 14.3445 18.3414H2.87243C1.58965 18.3267 0.554688 17.2841 0.554688 16.0066V2.8348C0.554688 1.54258 1.58965 0.5 2.87243 0.5H12.7702C14.053 0.5 15.0879 1.54258 15.0879 2.8348V4.02423C15.0879 4.30323 15.3212 4.53818 15.5981 4.53818H17.1287C18.4115 4.53818 19.4465 5.58076 19.4465 6.87298V19.986C19.4319 20.2651 19.1987 20.5 18.9217 20.5ZM2.90158 16.4765H14.9422C15.0442 16.4765 15.1463 16.5059 15.2337 16.5646L17.0996 17.8862C17.3037 18.033 17.5952 17.8862 17.5952 17.6219V6.90235C17.5952 6.62335 17.362 6.3884 17.085 6.3884H15.5836C15.3066 6.3884 15.0734 6.62335 15.0734 6.90235V13.7893C15.0734 14.0683 14.8401 14.3032 14.5632 14.3032H5.40883C5.13186 14.3032 4.89863 14.0683 4.89863 13.7893V5.05213C4.89863 4.77313 5.13186 4.53818 5.40883 4.53818H12.7411C13.018 4.53818 13.2512 4.30323 13.2512 4.02423V2.86417C13.2512 2.58517 13.018 2.35022 12.7411 2.35022H2.90158C2.62462 2.35022 2.39139 2.58517 2.39139 2.86417V15.9626C2.39139 16.2562 2.61004 16.4765 2.90158 16.4765ZM6.98314 12.4383H12.9889C13.1346 12.4383 13.2367 12.3209 13.2367 12.1887V6.63803C13.2367 6.49119 13.1201 6.3884 12.9889 6.3884H6.98314C6.83737 6.3884 6.73533 6.50587 6.73533 6.63803V12.1887C6.73533 12.3355 6.85195 12.4383 6.98314 12.4383Z"
        fill="#161616"
      />
    </svg>
  );
};
