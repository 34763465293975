import React from "react";

export const Bub = () => {
  return (
    <svg width="300" height="229" viewBox="0 0 300 229" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M300 114.607C300 139 293.893 228.143 150 228.143C108.964 228.143 0 228.571 0 228.571V114.607C0 46.357 55.1071 0 150 0C232.857 0 300 33.8927 300 114.607Z"
        fill="#5724E9"
      />
      <ellipse cx="109.44" cy="82.5001" rx="22.4398" ry="47.2669" fill="white" />
      <ellipse cx="189.56" cy="82.5001" rx="22.4398" ry="47.2669" fill="white" />
    </svg>
  );
};
