import React from "react";

export const Arpu = ({ alt }: { alt: boolean }) => {
  if (alt) {
    return (
      <svg width="54" height="12" viewBox="0 0 54 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.68456 5.98485V9.90087C7.68456 10.6163 7.10459 11.1962 6.38919 11.1962C5.67376 11.1962 5.09378 10.6163 5.09378 9.90087V5.98481C5.09378 5.26942 5.67376 4.68945 6.38919 4.68945C7.10459 4.68945 7.68456 5.26942 7.68456 5.98485ZM2.94405 8.66422V9.90087C2.94405 10.6163 2.36409 11.1962 1.64866 11.1962C0.933235 11.1962 0.353271 10.6163 0.353271 9.90087V8.66422C0.353271 7.94879 0.933235 7.36887 1.64866 7.36887C2.36409 7.36887 2.94405 7.94879 2.94405 8.66422ZM12.4251 2.06881V9.90087C12.4251 10.6163 11.8451 11.1962 11.1296 11.1962C10.4143 11.1962 9.83428 10.6163 9.83428 9.90087V2.06881C9.83428 1.35339 10.4143 0.773438 11.1296 0.773438C11.8451 0.773438 12.4251 1.3534 12.4251 2.06881Z"
          fill="#40BF9C"
          stroke="#38C09C"
          strokeWidth="0.70696"
        />
        <path
          d="M25.3501 11.3971L21.2749 0.550781H19.4775L15.0459 11.3971H17.5096L18.2379 9.38285H22.0961L22.7934 11.3971H25.3501ZM20.2058 3.92866H20.2212L21.4763 7.56993H18.8887L20.2058 3.92866Z"
          fill="#0C1D26"
        />
        <path
          d="M26.6721 0.550781V11.3971H28.8879V7.0741H29.2753L32.2503 11.3971H35.0394C34.2956 10.39 32.6842 8.17422 31.7235 6.8262C32.9321 6.42333 34.0787 5.49365 34.0787 3.8047C34.0787 1.44948 31.8629 0.550781 30.112 0.550781H26.6721ZM28.8879 2.42566H30.05C31.0417 2.42566 31.708 2.99897 31.708 3.85118C31.708 4.79636 30.8712 5.19923 29.7556 5.19923H28.8879V2.42566Z"
          fill="#0C1D26"
        />
        <path
          d="M36.3376 0.550781V11.3971H38.5534V7.30653H39.793C41.6524 7.30653 43.8527 6.40782 43.8527 3.91316C43.8527 1.44948 41.6369 0.550781 39.793 0.550781H36.3376ZM38.5534 2.42566H39.731C40.7847 2.42566 41.4975 2.99897 41.4975 3.95964C41.4975 5.0443 40.6143 5.43167 39.4366 5.43167H38.5534V2.42566Z"
          fill="#0C1D26"
        />
        <path
          d="M54.0003 0.550781H51.7845V6.57826C51.7845 8.65459 50.9477 9.64625 49.6309 9.64625C48.3137 9.64625 47.4769 8.65459 47.4769 6.57826V0.550781H45.2612V6.28385C45.2612 9.69273 46.5626 11.5831 49.6 11.5831C52.6988 11.5831 54.0003 9.69273 54.0003 6.28385V0.550781Z"
          fill="#0C1D26"
        />
      </svg>
    );
  }
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4102 10.5V17.7863C14.4102 19.1174 13.3311 20.1965 12.0001 20.1965C10.6689 20.1965 9.5898 19.1174 9.5898 17.7863V10.5C9.5898 9.16889 10.6689 8.08978 12.0001 8.08978C13.3311 8.08978 14.4102 9.16889 14.4102 10.5ZM5.58997 15.4854V17.7863C5.58997 19.1174 4.51088 20.1965 3.17975 20.1965C1.84862 20.1965 0.769531 19.1174 0.769531 17.7863V15.4854C0.769531 14.1542 1.84862 13.0752 3.17975 13.0752C4.51088 13.0752 5.58997 14.1542 5.58997 15.4854ZM23.2305 3.21374V17.7863C23.2305 19.1174 22.1514 20.1965 20.8202 20.1965C19.4891 20.1965 18.41 19.1174 18.41 17.7863V3.21374C18.41 1.88261 19.4891 0.803528 20.8202 0.803528C22.1514 0.803528 23.2305 1.88262 23.2305 3.21374Z"
        fill="#40BF9C"
        stroke="#38C09C"
        strokeWidth="1.31538"
      />
    </svg>
  );
};
